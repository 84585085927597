@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-2xl;
  }
  h4 {
    @apply text-xl;
  }
}

@layer components {
  .interactive {
    @apply pointer-events-auto touch-auto;
  }
  .non-interactive {
    @apply pointer-events-none touch-none;
  }
}

.p-inputtext, .p-password {
  width: 100%;
}

.p-dropdown-label .p-inputtext {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  padding-bottom: 0;
  padding-top: 0;
}
